var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"items":_vm.scoreItems,"headers":_vm.scoreHeaders,"mobile-breakpoint":"200","hide-default-footer":"","value":[{ id: _vm.currentPlayer }],"dense":"","disable-sort":"","disable-pagination":"","disable-filtering":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "),(_vm.dealer == item.id)?_c('v-chip',{attrs:{"x-small":"","outlined":"","color":"blue"}},[_vm._v(" Dealer ")]):_vm._e()]}},{key:"item.want",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.want)+" "),(_vm.currentPlayer == item.id && _vm.roundState == 'bidding')?_c('v-chip',{staticClass:"pr-2 pl-2",attrs:{"x-small":"","color":"orange"}},[_vm._v(" Bidding ")]):_vm._e()]}},{key:"item.got",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.got)+" "),(item.got !== null)?_c('v-chip',{staticClass:"badge-chip white--text pr-1 pl-1 mb-3",attrs:{"x-small":"","color":_vm.colorForScore(_vm.calculateRoundScore(item.want, item.got))}},[_vm._v(_vm._s(_vm.roundScoreChip(item.want, item.got)))]):_vm._e()]}},{key:"item.score",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.playerTotals[("p" + (item.id))])+" ")]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }